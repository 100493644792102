// import { useEffect, useState } from "react";
import { useModifyButtonPromotions } from "@Hooks/usePromotions/useModifyButtonPromotions";
import {
  // SearchAndFilter,
  CriticalErrorPage,
  // PaginationWrapper,
  // ModalAcceptCancel,
  // @ts-ignore
} from "@viuti/recursos";
import { SetButtonPropsFunction } from "@Models/interfaces/props";
import { PromotionsTable } from "@Components/PromotionsTable/PromotionsTable";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
// import { useFilteredPromotion } from "@Hooks/modalHooks/useFilteredPromotion";
import { Loader } from "@Components/Loader/Loader";
import { changeLastView } from "@ReduxService/states/lastViewSlice";
// import { useUpdatePromotionsInRedux } from "@Hooks/usePromotions/useUpdatePromotionsInRedux";
// import style from "./PromotionList.module.css";

export const PromotionList = ({
  setButtonProps,
  setButtonTwoProps,
  handleReset,
}: {
  setButtonProps: SetButtonPropsFunction;
  setButtonTwoProps: SetButtonPropsFunction;
  handleReset: any;
}) => {
  // Use the change view to return the user to the same view
  const dispatch = useAppDispatch();
  dispatch(changeLastView("Paquetes"));
  useModifyButtonPromotions(setButtonProps, setButtonTwoProps);
  // const [searchInput, setSearchInput] = useState("");

  const promotions = useAppSelector((state) => state.promotions.promotions);

  const criticalError = useAppSelector(
    (state) => state.criticalError.criticalErrorPromotions
  );

  const isLoading = useAppSelector(
    (state) => state.loaderConfig.loaderPromotions
  );

  // const { handleOpenFilterClick, modalProps } = useFilteredPromotion();

  if (isLoading) return <Loader />;

  if (criticalError) {
    return <CriticalErrorPage />;
  }

  return <PromotionsTable items={promotions} handleReset={handleReset} />;
};
// // <div className={style.promotionList}>

// {
//   /* <SearchAndFilter
//       placeholder="Buscar paquete por nombre..."
//       searchInput={searchInput}
//       setSearchInput={setSearchInput}
//       isFilterAvailable={false}
//       // handleOpenFilter={handleOpenFilterClick}
//     /> */
// }

// {
//   /* <ModalAcceptCancel {...modalProps} /> */
// }
// {
//   /* </div> */
// }
