import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GiftcardForm } from "@Models/interfaces/giftcards";
import { postGiftcardAdapter } from "@Adapters/giftcards/postNewGiftcard.adapter";
// import { updateGiftcardAdapter } from "@Adapters/giftcards/updateGiftcard.adapter";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { PROMOTION_ROUTE } from "@Models/routes";
import { adapterGetGiftcardById } from "@Adapters/giftcards/getGiftcardById.adapter";
import { debounce } from "lodash";
import { validateGiftcardCode } from "@Services/giftcards/validateGiftcardCode.service";

export const useGiftcardForm = (isEdit: boolean, giftcardId?: string) => {
  const GIFTCARD_PERIOD = ["Días", "Meses", "Años"];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCodeLoading, setIsCodeLoading] = useState(false);

  const [giftcardForm, setGiftcardForm] = useState<GiftcardForm>({
    period: 0,
    periodSelected: "Días",
    periodDays: "1",
    amount: "",
    code: "",
    modalityId: undefined,
  });

  const [giftcardFormError, setGiftcardFormError] = useState({
    amount: "",
    periodDays: "",
    code: "",
  });

  const [isCodeValid, setIsCodeValid] = useState(false);
  const [codeError, setCodeError] = useState("");

  useEffect(() => {
    if (isEdit && giftcardId) {
      loadGiftcardData(giftcardId);
    }
  }, [isEdit, giftcardId]);

  const loadGiftcardData = async (id: string) => {
    const response = await adapterGetGiftcardById(id);
    if (response.isSuccess) {
      // setGiftcardForm(response.data);
    }
  };

  const validateCode = async (code: string) => {
    if (code.length < 5) {
      setCodeError("El código debe tener al menos 5 caracteres");
      setIsCodeValid(false);
      return;
    }

    if (!/(?=.*[A-Z])(?=.*[0-9])/.test(code)) {
      setCodeError("El código debe contener al menos una letra y un número");
      setIsCodeValid(false);
      return;
    }

    setIsCodeLoading(true);
    setCodeError("");

    const response = await validateGiftcardCode(code);
    if (!response.isSuccess || !response.isAvailable) {
      setCodeError("El código no está disponible");
      setIsCodeValid(false);
    } else {
      setIsCodeValid(true);
    }

    setIsCodeLoading(false);
  };

  const debouncedValidateCode = debounce(validateCode, 300);

  // Handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "code") {
      const upperValue = value.toUpperCase();
      if (upperValue.length > 10) return;
      if (!/^[A-Z0-9]*$/.test(upperValue)) return;

      setGiftcardForm((prev) => ({
        ...prev,
        [name]: upperValue,
      }));

      if (upperValue.length < 5) {
        setCodeError("El código debe tener al menos 5 caracteres");
        setIsCodeValid(false);
      } else if (!/(?=.*[A-Z])(?=.*[0-9])/.test(upperValue)) {
        setCodeError("El código debe contener al menos una letra y un número");
        setIsCodeValid(false);
      } else {
        debouncedValidateCode(upperValue);
      }
      return;
    }

    // Applied the validators
    if (name === "giftcardCode" && value.length > 10) return;

    const periodSelected =
      name === "period" ? GIFTCARD_PERIOD[value] : giftcardForm.periodSelected;

    //Update the state
    setGiftcardForm({
      ...giftcardForm,
      [name]: value,
      periodSelected,
    });
  };

  // Handle errors
  const handleSubmitForm = async () => {
    let errorObj = {
      amount: "",
      periodDays: "",
      code: "",
    };

    if (giftcardForm.amount.length < 1)
      errorObj.amount = "El campo de monto es obligatorio";

    if (giftcardForm.periodDays.length < 1)
      errorObj.periodDays = "El campo de días de vigencia es obligatorio";

    if (!giftcardForm.code || giftcardForm.code.length < 5) {
      errorObj.code = "El código debe tener al menos 5 caracteres";
    }

    if (!isCodeValid) {
      errorObj.code = "El código no es válido";
    }

    setGiftcardFormError(errorObj);
    if (Object.values(errorObj).some((error) => error !== "")) return;

    setIsSubmitting(true);
    try {
      const adapterResponse = await postGiftcardAdapter(giftcardForm);

      dispatch(
        updateNotification({
          message: adapterResponse.message,
          status: adapterResponse.isSuccess ? 200 : 400,
          toastTimeDuration: 2000,
        })
      );

      if (adapterResponse.isSuccess) navigate(PROMOTION_ROUTE.HOME_ROUTE);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleInputChange,
    giftcardForm,
    handleSubmitForm,
    giftcardFormError,
    isSubmitting,
    isCodeValid,
    codeError,
    isCodeLoading,
  };
};
