import { giftcardsBaseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

interface IApiGetGiftcardsResponse {
  isSuccess: boolean;
  userMessage: string;
  data: {
    actualPage: number;
    maxPages: number;
    detail: {
      giftcardId: number;
      code: string;
      value: number;
      remainder: number;
      creationDate: string;
      saleDate: string;
      status: string;
      statusId: number;
      modality: string;
      modalityId: number;
      employeeCreation: string;
      employeeCreationId: number;
    }[];
  };
}

export const apiGetGiftcards = async (): Promise<IApiGetGiftcardsResponse> => {
  try {
    const PATH = giftcardsBaseUrl + "/GetGiftcards";
    const PARAMS = {};
    const AUTHORIZATION = { ...headerAuthorization };

    const CONFIGURATION = {
      params: PARAMS,
      ...AUTHORIZATION,
    };

    const backendResponse = await axios.get(PATH, CONFIGURATION);
    return {
      isSuccess: true,
      userMessage: backendResponse.data.message,
      data: backendResponse.data.data,
    };
  } catch (error) {
    // console.log("error", error);
    return {
      isSuccess: false,
      userMessage: "Error al obtener las giftcards",
      data: {
        actualPage: 0,
        maxPages: 0,
        detail: [],
      },
    };
  }
};
