// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cxPktk1zkxoN7hUHCK_A {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.88rem;
}

.cxPktk1zkxoN7hUHCK_A a {
  color: unset;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Giftcards/CreateGiftCard/components/CreateGiftcarNote/CreateGiftcardNote.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;;EAErB;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".giftcard_note_ul {\n  margin-left: 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  font-size: 0.88rem;\n}\n\n.giftcard_note_ul a {\n  color: unset;\n  font-weight: 700;\n  text-decoration: none;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"giftcard_note_ul": `cxPktk1zkxoN7hUHCK_A`
};
export default ___CSS_LOADER_EXPORT___;
