// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G27rYaqPMfEZt4mQGLZK {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1.5rem;
}

.G27rYaqPMfEZt4mQGLZK {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: auto;
}

.i2lcGSspAmD0GwkT9QzQ {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
}

.D2Qxmc8HTtAZBng56iN3 {
  width: 100%;
  max-height: -webkit-fill-available;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #937cf4;
  background: #fff;
  margin-bottom: 2rem;
}

.hUb9a5jeIF1heVQZEygK {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (width <= 830px) {
  .D2Qxmc8HTtAZBng56iN3 {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (width <= 460px) {
  .i2lcGSspAmD0GwkT9QzQ {
    display: flex;
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Giftcards/components/GiftcardForm/GiftcardForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,wDAAwD;EACxD,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kCAAkC;EAClC,aAAa;EACb,qCAAqC;EACrC,aAAa;EACb,SAAS;EACT,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;AACF","sourcesContent":[".giftcard_main_content {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding-top: 1.5rem;\n}\n\n.giftcard_main_content {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n  overflow-y: auto;\n}\n\n.giftcard_dates {\n  display: grid;\n  grid-template-columns: calc(50% - 10px) calc(50% - 10px);\n  gap: 20px;\n}\n\n.box_container {\n  width: 100%;\n  max-height: -webkit-fill-available;\n  display: grid;\n  grid-template-columns: auto auto auto;\n  padding: 24px;\n  gap: 24px;\n  border-radius: 8px;\n  border: 1px solid #937cf4;\n  background: #fff;\n  margin-bottom: 2rem;\n}\n\n.giftcard_note_ul {\n  margin-left: 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n@media screen and (width <= 830px) {\n  .box_container {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n@media screen and (width <= 460px) {\n  .giftcard_dates {\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"giftcard_main_content": `G27rYaqPMfEZt4mQGLZK`,
	"giftcard_dates": `i2lcGSspAmD0GwkT9QzQ`,
	"box_container": `D2Qxmc8HTtAZBng56iN3`,
	"giftcard_note_ul": `hUb9a5jeIF1heVQZEygK`
};
export default ___CSS_LOADER_EXPORT___;
