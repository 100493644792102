import { IGiftcard } from "@Models/interfaces/giftcards";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGiftcardType {
  id: number;
  name: string;
}

export interface GiftcardsState {
  giftcards: IGiftcard[];
  currentPage: number;
  totalPages: number;
  giftcardTypes: IGiftcardType[];
}

const initialState: GiftcardsState = {
  giftcards: [],
  currentPage: 1,
  totalPages: 1,
  giftcardTypes: [],
};

const giftcardsSlice = createSlice({
  name: "giftcards",
  initialState,
  reducers: {
    updateGiftcards(state, action: PayloadAction<IGiftcard[]>) {
      state.giftcards = action.payload;
    },
    updateCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    updateTotalPages(state, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateGiftcardTypes(state, action: PayloadAction<IGiftcardType[]>) {
      state.giftcardTypes = action.payload;
    },
    removeGiftcard(state, action: PayloadAction<number>) {
      state.giftcards = state.giftcards.filter(
        (giftcard) => giftcard.id !== action.payload
      );
    },
  },
});

export const {
  updateGiftcards,
  updateCurrentPage,
  updateTotalPages,
  updateGiftcardTypes,
  removeGiftcard,
} = giftcardsSlice.actions;
export default giftcardsSlice.reducer;
