import { IGiftcard } from "@Models/interfaces/giftcards";
import { apiGetGiftcardDetails } from "@Services/giftcards/getGiftcardById.service";
import { apiGetGiftcards } from "@Services/giftcards/getGiftcards.service";
import { formatToSoles } from "@Utilities/FormatsHandlers";

interface IAdapterGetGiftcardByIdResponse {
  isSuccess: boolean;
  userMessage: string;
  data: IGiftcard;
}

export const adapterGetGiftcardById = async (
  giftcardId: string
): Promise<IAdapterGetGiftcardByIdResponse> => {
  try {
    const apiResponse = await apiGetGiftcardDetails(giftcardId);
    const { isSuccess, data, message } = apiResponse;

    // If the status is not success
    if (!isSuccess)
      return {
        isSuccess: false,
        userMessage: message,
        data: null,
      };

    // If the status is success
    return {
      isSuccess: true,
      userMessage: message,
      data: {
        id: data.giftcardId,
        name: data.code,
        status: {
          name:
            data.status.charAt(0).toUpperCase() +
            data.status.slice(1).toLowerCase(),
          id: data.statusId,
        },
        date: {
          start: convertDateFormat(data.creationDate),
          end: convertDateFormat(data.creationDate),
          created: convertDateFormat(data.creationDate),
        },
        price: formatToSoles(data.value),
      },
    };
  } catch (err) {
    return {
      isSuccess: false,
      userMessage: "Error al obtener los detalles de la giftcard",
      data: null,
    };
  }
};

function convertDateFormat(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}
