import { downloadTemplateGiftcard_service } from "@Services/giftcards/downloadTemplateGiftcard.service";
import { updateNotification } from "@ReduxService/states/notificationSlice";

export const downloadTemplateGiftcardAdapter = async (
  dispatch,
  setIsDownloading
) => {
  try {
    setIsDownloading(true);
    const response = await downloadTemplateGiftcard_service();

    // console.log("response", response);

    if (response.isSuccess && response.data) {
      const link = document.createElement("a");
      const base64String = response.data.base64Templane;
      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
      link.download = "plantilla_giftcards.xlsx";
      link.click();
    }

    dispatch(
      updateNotification({
        message: response.message,
        status: response.isSuccess ? 200 : 400,
        toastTimeDuration: 4000,
      })
    );
  } catch (error) {
    dispatch(
      updateNotification({
        message: "Error al descargar la plantilla",
        status: 500,
        toastTimeDuration: 4000,
      })
    );
  } finally {
    setIsDownloading(false);
  }
};
