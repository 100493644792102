// @ts-ignore
import { InfoNote } from "@viuti/recursos";
import style from "./CreateGiftcardNote.module.css";
import { GIFTCARD_ROUTE } from "@Models/routes";

export const CreateGiftcardNote = () => {
  return (
    <InfoNote type="info">
      <ul className={style.giftcard_note_ul}>
        <li>
          Tenga en cuenta que la fecha de vencimiento de la tarjeta de regalo
          comenzará a correr a partir del momento en que se utilice por primera
          vez en caja. Esto significa que los clientes tendrán un período
          específico a partir del primer uso para gastar el saldo de la tarjeta.
        </li>
        <li>
          Para subir muchos giftcards a la vez puedes hacerlo mediante el{" "}
          <a href={`/marketing${GIFTCARD_ROUTE.CREATE_MASSIVE_GIFTCARDS}`}>
            creador masivo
          </a>
          .
        </li>
      </ul>
    </InfoNote>
  );
};
