import { adapterGetGiftcards } from "@Adapters/giftcards/getGiftcards.adapter";
import { useAppDispatch } from "@Hooks/store";
import {
  updateGiftcards,
  updateTotalPages,
} from "@ReduxService/states/giftcardsSlice";
import { useState } from "react";

const DEFAULT_PAGE_LIMIT = 10;

export const usePaginatedGiftCard = () => {
  const [criticalPage, setCriticalPage] = useState(false);
  const dispatch = useAppDispatch();

  // 🎯 Goal: get all the giftcards into the page selected and save it on "giftcardsPaginated"
  // If the giftcards are cached, the giftcards is not fetched again
  const getGiftcardsByPage = async ({
    pageNumber,
    pageLimit = DEFAULT_PAGE_LIMIT,
  }: {
    pageNumber: number;
    pageLimit?: number;
  }) => {
    const response = await adapterGetGiftcards();
    // console.log("response", response);

    if (response.isSuccess === false) return setCriticalPage(true);

    const giftcards_data = response.data;
    dispatch(updateGiftcards(giftcards_data.giftcards));
    dispatch(updateTotalPages(giftcards_data.totalPages));
  };

  return {
    getGiftcardsByPage,
    showCriticalPage: criticalPage,
  };
};
