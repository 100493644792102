import { giftcardsBaseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

interface IValidateGiftcardCodeResponse {
  isSuccess: boolean;
  message: string;
  isAvailable: boolean;
}

export const validateGiftcardCode = async (
  code: string
): Promise<IValidateGiftcardCodeResponse> => {
  try {
    const PATH = `${giftcardsBaseUrl}/AvailableCode`;
    const response = await axios.get(PATH, {
      ...headerAuthorization,
      params: { code },
    });

    return {
      isSuccess: true,
      message: "Código validado correctamente",
      isAvailable: response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      message: "Error al validar el código",
      isAvailable: false,
    };
  }
};
