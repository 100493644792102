import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useModifyButtonPromotions = (
  setButtonProps,
  setButtonTwoProps
) => {
  const navigation = useNavigate();

  useEffect(() => {
    // console.log("promotions buttons");
    setButtonProps({
      textBttn: "Nuevo paquete",
      handleClick: () => navigation("/nuevo-paquete"),
      isDisabled: false,
      isPrimary: true,
    });
    setButtonTwoProps({
      textBttn: "Carga masiva",
      handleClick: () => navigation("/carga-masiva-paquetes"),
      isDisabled: false,
      isPrimary: false,
      isHidden: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
