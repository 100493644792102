import { IGiftcard } from "@Models/interfaces/giftcards";
import { apiGetGiftcards } from "@Services/giftcards/getGiftcards.service";
import { formatToSoles } from "@Utilities/FormatsHandlers";

interface IAdapterGetGiftcardsResponse {
  isSuccess: boolean;
  userMessage: string;
  data: {
    currentPage: number;
    totalPages: number;
    giftcards: IGiftcard[];
  };
}
export const adapterGetGiftcards =
  async (): Promise<IAdapterGetGiftcardsResponse> => {
    try {
      const apiResponse = await apiGetGiftcards();
      const { isSuccess, userMessage, data } = apiResponse;

      // console.log("apiResponse", apiResponse);

      // If the status is not success
      if (!isSuccess)
        return {
          isSuccess: false,
          userMessage: userMessage,
          data: {
            currentPage: 0,
            totalPages: 0,
            giftcards: [],
          },
        };

      // console.log(data);

      const formattedData = data.detail.map((giftcard) => {
        return {
          id: giftcard.giftcardId,
          name: giftcard.code,
          status: {
            name:
              giftcard.status.charAt(0).toUpperCase() +
              giftcard.status.slice(1).toLowerCase(),
            id: giftcard.statusId,
          },
          date: {
            start: convertDateFormat(giftcard.creationDate),
            end: convertDateFormat(giftcard.creationDate),
            created: convertDateFormat(giftcard.creationDate),
          },
          price: formatToSoles(giftcard.value),
        };
      });
      // If the status is success
      return {
        isSuccess: true,
        userMessage: userMessage,
        data: {
          currentPage: data.actualPage,
          totalPages: data.maxPages,
          giftcards: formattedData,
        },
      };
    } catch (err) {
      // console.log("err", err);
      return {
        isSuccess: false,
        userMessage: "Error al obtener las giftcards",
        data: {
          currentPage: 0,
          totalPages: 0,
          giftcards: [],
        },
      };
    }
  };

function convertDateFormat(dateString: string) {
  if (!dateString) return "";

  try {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const date = new Date(`${year}-${month}-${day}`);
    if (isNaN(date.getTime())) {
      return "";
    }

    // Formatear la fecha al formato dd-mm-yyyy
    const formattedDay = date.getDate().toString().padStart(2, "0");
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    const formattedYear = date.getFullYear();

    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  } catch (error) {
    console.error("Error al convertir la fecha:", error);
    return "";
  }
}
