import { useAppDispatch } from "@Hooks/store";
import { usePaginatedGiftCard } from "@Pages/Giftcards/GiftCardList/hook/usePaginatedGiftcards";
import { useEffect } from "react";

export const useUpdateGiftcardsInRedux = (currentPagination: number) => {
  const dispatch = useAppDispatch();
  const { getGiftcardsByPage } = usePaginatedGiftCard();

  useEffect(() => {
    getGiftcardsByPage({
      pageNumber: currentPagination,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPagination]);
};
