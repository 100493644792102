import {
  HeaderNavigation,
  TabNavigation,
  PaginationWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import { PromotionList } from "./Promotions/PromotionList/PromotionList";
import { useEffect, useState } from "react";
import { ButtonProps } from "@Models/interfaces/props";
import GiftcardList from "./Giftcards/GiftCardList/GiftCardList";
import { CustomerCategoryList } from "./CustommerCategories/CustomerCategoryList/CustomerCategoryList";
import { useAppSelector } from "@Hooks/store";
import style from "./MainView.module.css";
import { useUpdatePromotionsInRedux } from "@Hooks/usePromotions/useUpdatePromotionsInRedux";
import { useUpdateGiftcardsInRedux } from "@Hooks/useGiftCards/useUpdateGiftcardsInRedux";

export const MainView = () => {
  const lastViewReduxContext = useAppSelector((state) => state.lastView);
  const promotionsTotalPages = useAppSelector(
    (state) => state.promotions.totalPages
  );
  const giftcardsTotalPages = useAppSelector(
    (state) => state.giftcards.totalPages
  );

  const [buttonProps, setButtonProps] = useState<ButtonProps>({
    textBttn: "Nuevo paquete",
    handleClick: () => {},
    isDisabled: false,
    isPrimary: true,
  });
  const [buttonTwoProps, setButtonTwoProps] = useState<ButtonProps>(null);

  const [currentPagination, setCurrentPagination] = useState(1);
  const itemsPerPage = 10;

  const totalPages =
    lastViewReduxContext.lastView === "Giftcards"
      ? giftcardsTotalPages
      : promotionsTotalPages;

  useUpdatePromotionsInRedux(
    lastViewReduxContext.lastView === "Paquetes" ? currentPagination : 1
  );

  useUpdateGiftcardsInRedux(
    lastViewReduxContext.lastView === "Giftcards" ? currentPagination : 1
  );

  const handleReset = () => {
    setCurrentPagination(1);
  };

  const navigationViews = [
    {
      name: "Paquetes",
      component: (
        <PromotionList
          setButtonProps={setButtonProps}
          setButtonTwoProps={setButtonTwoProps}
          handleReset={handleReset}
        />
      ),
    },
    // {
    //   name: "Categoría de clientes",
    //   component: <CustomerCategoryList setButtonProps={setButtonProps} />,
    // },
    // {
    //   name: "Giftcards",
    //   component: (
    //     <GiftcardList
    //       setButtonProps={setButtonProps}
    //       setButtonTwoProps={setButtonTwoProps}
    //       handleReset={handleReset}
    //     />
    //   ),
    // },
  ];

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title={"Marketing"}
        previousAction={false}
        previousActionMovement={""}
        buttonProps={buttonProps}
        buttonTwoProps={buttonTwoProps}
      />
      <TabNavigation
        views={navigationViews}
        initialView={lastViewReduxContext.lastViewIndex}
      />
      {totalPages > 1 && (
        <div className={style.pagination_wrapper}>
          <PaginationWrapper
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            optionsPerPage={itemsPerPage}
            numberOfButtons={totalPages}
          />
        </div>
      )}
    </div>
  );
};
