import style from "./RowActionsPromotions.module.css";
import { CommonArrowRightIcon, TrashCanIcon } from "@Models/icons";
import { createPortal } from "react-dom";
// @ts-ignore
import { Icon, ModalAcceptCancel } from "@viuti/recursos";
import useDeletePromotion from "@Hooks/modalHooks/useDeletePromotion";
import useDeleteGiftcard from "@Hooks/modalHooks/useDeleteGiftcard";

export const RowActionsPromotions = ({
  item,
  isHovered,
  screenWidth,
  view = "PROMOTION",
  handleReset,
}) => {
  const isGiftcard = view === "GIFTCARDS";
  const { handleOpenModalDelete, modalProps } = useDeletePromotion(
    item.id,
    handleReset
  );
  const {
    handleOpenModalDelete: handleDeleteClickGiftcards,
    modalProps: modalPropsGiftcard,
  } = useDeleteGiftcard(item.id, handleReset);

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isGiftcard) {
      handleDeleteClickGiftcards(e);
    } else {
      handleOpenModalDelete(e);
    }
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <span className={style.row_actions}>
        {screenWidth > 900 && (
          <div
            style={{ opacity: isHovered === item.id ? 1 : 0 }}
            className={style.icon__edit}
          >
            <Icon
              path={CommonArrowRightIcon}
              color="#45348E"
              size={16}
              alt="Editar"
            />
          </div>
        )}
        <button onClick={handleDelete} className={style.icon__delete}>
          <Icon path={TrashCanIcon} color="#C36363" size={16} alt="Eliminar" />
        </button>
      </span>
      <div>
        {createPortal(
          isGiftcard ? (
            <ModalAcceptCancel {...modalPropsGiftcard} />
          ) : (
            <ModalAcceptCancel {...modalProps} />
          ),
          document.body
        )}
      </div>
    </>
  );
};
