import { giftcardsBaseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export const createMassiveGiftcards_service = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      `${giftcardsBaseUrl}/CreateMasiveGiftcards`,
      formData,
      {
        ...headerAuthorization,
        headers: {
          ...headerAuthorization.headers,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return {
      isSuccess: true,
      message: "Giftcards creadas exitosamente",
    };
  } catch (error) {
    const errorMessage = error.response.data.message;
    // console.log("error", errorMessage);

    return {
      isSuccess: false,
      message: errorMessage ?? "Error al crear las giftcards",
    };
  }
};
