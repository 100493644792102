import { GiftcardForm, IGiftcardPeriod } from "@Models/interfaces/giftcards";
import { postGiftcardService } from "@Services/giftcards/postGiftcards.service";

const GENERAL_ERROR_MSG =
  "Lo sentimos, no se pudo crear una nueva giftcard. Por favor, inténtelo de nuevo más tarde.";
const GENERAL_SUCCESS_MSG = "La giftcard se ha creado con éxito.";

export const postGiftcardAdapter = async (newGiftcard: GiftcardForm) => {
  const convertToDays = (
    periodDays: number,
    periodSelected: IGiftcardPeriod
  ): number => {
    switch (periodSelected) {
      case "Meses":
        return periodDays * 30;
      case "Años":
        return periodDays * 365;
      case "Días":
      default:
        return periodDays;
    }
  };

  const backendObj = {
    code: newGiftcard.code,
    typeGiftCard: newGiftcard.modalityId,
    price: newGiftcard.amount,
    daysOfValidity: convertToDays(
      parseInt(newGiftcard.periodDays),
      newGiftcard.periodSelected
    ),
  };

  try {
    const backendResponse = await postGiftcardService(backendObj);
    return {
      isSuccess: backendResponse.isSuccess,
      message: GENERAL_SUCCESS_MSG,
    };
  } catch (err) {
    return {
      isSuccess: false,
      message: GENERAL_ERROR_MSG,
    };
  }
};
