import { giftcardsBaseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export const downloadTemplateGiftcard_service = async () => {
  try {
    const response = await axios.get(
      `${giftcardsBaseUrl}/GetTemplateGifcardMasive`,
      headerAuthorization
    );

    return {
      isSuccess: true,
      data: response.data.data,
      message: "Plantilla descargada exitosamente",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: null,
      message: "Error al descargar la plantilla",
    };
  }
};
