import { updateNotification } from "@ReduxService/states/notificationSlice";
import { removeGiftcard } from "@ReduxService/states/giftcardsSlice";
import { deleteGiftcards_service } from "@Services/giftcards/deleteGiftcards.service";

export const deleteGiftcardsAdapter = async (
  e,
  id,
  dispatch,
  handleCloseModalDelete,
  setIsLoading,
  handleReset
) => {
  try {
    e.stopPropagation();
    setIsLoading(true);
    const response = await deleteGiftcards_service(id);
    if (response.isSuccess) {
      dispatch(removeGiftcard(id));
      handleCloseModalDelete(e);
      handleReset();
    } else {
      setIsLoading(false);
    }
    dispatch(
      updateNotification({
        message: response.message,
        status: response.status,
        toastTimeDuration: 4000,
      })
    );
  } catch (error) {
    dispatch(
      updateNotification({
        message: "No se pudo eliminar la giftcard. Intente de nuevo.",
        status: 500,
        toastTimeDuration: 4000,
      })
    );
  } finally {
    setIsLoading(false);
  }
};
