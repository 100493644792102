import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "./PromotionForm.module.css";
import {
  HeaderNavigation,
  AsideSectionMenu,
  CriticalErrorPage,
  ModalUnsavedChanges,
  // @ts-ignore
} from "@viuti/recursos";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { SelectPaymentMethods } from "./components/SelectPaymentMethods/SelectPaymentMethods";
import { SelectLounges } from "./components/SelectLounges/SelectLounges";
import { CareChannels } from "./components/CareChannels/CareChannels";
import { SelectServices } from "./components/SelectServices/SelectServices";
import { SelectProducts } from "./components/SelectProducts/SelectProducts";
import { DateOfValidation } from "./components/DateOfValidation/DateOfValidation";
import { PromotionName } from "./components/PromotionName/PromotionName";
import { Availability } from "./components/Availability/Availability";
import { TypeDiscount } from "./components/TypeDiscount/TypeDiscount";
import { postPromotion_adapter } from "@Adapters/promotions/postPromotion.adapter";
import { PromotionCode } from "./components/PromotionCode/PromotionCode";
import { PromotionStock } from "./components/PromotionStock/PromotionStock";
import { PromotionDiscount } from "./components/PromotionDiscount/PromotionDiscount";
import { PromotionConditions } from "./components/PromotionConditions/PromotionConditions";
import useFormValidation from "@Hooks/useFormValidation/useFormValidation";
import { PromotionProvider } from "@Context/promotionContext";
import { Loader } from "@Components/Loader/Loader";
import {
  clearForm,
  ItemProduct,
  ItemService,
} from "@ReduxService/states/promotionsFormSlice";
import { changeLastView } from "@ReduxService/states/lastViewSlice";
import { PackageCost } from "./components/PackageCost/PackageCost";
import { SelectTypePromotion } from "./components/SelectTypePromotion/SelectTypePromotion";
import { useAutoCompletePromotionForm } from "@Hooks/useAutoCompletePromotionForm/useAutoCompletePromotionForm";
import { TotalPackageValue } from "./components/TotalPackageValue/TotalPackageValue";
import { useFormPromotion } from "@Hooks/useFormPromotion/useFormPromotion";
import { putEditPackage_adapter } from "@Adapters/promotions/package/putEditPackage.adapter";

export const PromotionForm = ({ isEdit }: { isEdit: boolean }) => {
  //Set the last view redux state
  const dispatch = useAppDispatch();
  dispatch(changeLastView("Paquetes"));

  const navigation = useNavigate();
  const navigate = useNavigate();
  const [changesModalVisible, setChangesModalVisible] = useState(false);
  const [currentView, setCurrentView] = useState(0);
  const [isLoadingCreatePromotion, setIsLoadingCreatePromotion] =
    useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const criticalErrorFormPromotion = useAppSelector(
    (state) => state.criticalError.criticalErrorFormPromotion
  );
  const loaderFormPromotion = useAppSelector(
    (state) => state.loaderConfig.loaderFormPromotion
  );

  const form = useAppSelector((state) => state.promotionForm.form);
  const initialForm = useAppSelector(
    (state) => state.promotionInitialForm.initialForm
  );

  const savedServices = useAppSelector(
    (state) => state.promotionForm.savedServices
  );

  const savedProducts = useAppSelector(
    (state) => state.promotionForm.savedProducts
  );
  const initialSavedServices = useAppSelector(
    (state) => state.promotionInitialForm.savedServices
  );

  const initialSavedProducts = useAppSelector(
    (state) => state.promotionInitialForm.savedProducts
  );
  const services = useAppSelector((state) => state.services.services);
  const products = useAppSelector((state) => state.products.products);
  const totalServices = savedServices?.reduce(
    // @ts-ignore
    (total: number, savedService: ItemService) => {
      const service = services.find(
        (s: ItemService) => s.idService === savedService.idService
      );
      return service
        ? total + Number(service.price) * Number(savedService.quantity)
        : total;
    },
    0
  );

  const totalProducts = savedProducts?.reduce(
    // @ts-ignore
    (total: number, savedProduct: ItemProduct) => {
      const product = products.find(
        (p: ItemProduct) => p.idProduct === savedProduct.idProduct
      );
      return product
        ? total + Number(product.price) * Number(savedProduct.quantity)
        : total;
    },
    0
  );

  // @ts-ignore
  const total = totalServices + totalProducts;

  const { lounges, paymentMethods } = useFormPromotion(isEdit);
  const { id } = useParams();
  useAutoCompletePromotionForm(id, paymentMethods);
  useFormValidation(form, initialForm, setDisabledButton, total);

  const handleClick = async () => {
    if (isEdit) {
      await putEditPackage_adapter(
        id,
        form,
        initialForm,
        savedServices,
        savedProducts,
        initialSavedServices,
        initialSavedProducts,
        dispatch,
        setIsLoadingCreatePromotion,
        navigate
      );
    } else {
      await postPromotion_adapter(
        form,
        dispatch,
        navigation,
        setIsLoadingCreatePromotion
      );
    }
  };

  const buttonProps = {
    textBttn: "Guardar",
    handleClick: handleClick,
    isDisabled: isLoadingCreatePromotion || disabledButton,
    isLoading: isLoadingCreatePromotion,
    isPrimary: true,
  };

  const previousAction = () => {
    if (JSON.stringify(form) !== JSON.stringify(initialForm)) {
      setChangesModalVisible(true);
    } else {
      dispatch(clearForm());
      navigation("/");
    }
  };

  const headerTitle = isEdit ? "Paquete" : "Nuevo paquete";

  if (loaderFormPromotion) {
    return (
      <div id="viuti-front-mainContent" className={style.container}>
        <HeaderNavigation
          title={headerTitle}
          previousAction={previousAction}
          buttonProps={buttonProps}
        />
        <Loader />
      </div>
    );
  }

  if (criticalErrorFormPromotion) {
    return (
      <div id="viuti-front-mainContent" className={style.container}>
        <HeaderNavigation
          title={headerTitle}
          previousAction={previousAction}
          buttonProps={buttonProps}
        />
        <CriticalErrorPage />
      </div>
    );
  }
  const linkArray =
    form.typePromotion === "Cupón de descuento"
      ? [
          {
            name: "Datos básicos",
            href: "#datos-basicos",
          },
        ]
      : [
          {
            name: "Datos básicos",
            href: "#datos-basicos",
          },
          {
            name: "Productos y Servicios",
            href: "#productos-y-servicios",
          },
        ];

  return (
    <PromotionProvider form={form} dispatch={dispatch} isEdit={isEdit}>
      <div id="viuti-front-mainContent" className={style.container}>
        <HeaderNavigation
          title={headerTitle}
          previousAction={previousAction}
          buttonProps={buttonProps}
        />
        <div className={style.form}>
          <AsideSectionMenu
            linkArray={linkArray}
            currentSelected={currentView}
            setCurrentSelected={setCurrentView}
          >
            <div className={style.formContent}>
              {/* {!isEdit && <SelectTypePromotion />} */}
              {form.typePromotion !== "Cupón de descuento" && (
                <PromotionName setCurrentView={setCurrentView} />
              )}
              <DateOfValidation />
              <Availability />
              {form.typePromotion === "Cupón de descuento" && <PromotionCode />}
              <PromotionStock />
              {form.typePromotion !== "Cupón de descuento" && (
                <SelectPaymentMethods paymentMethods={paymentMethods} />
              )}
              {form.typePromotion !== "Paquete" && <TypeDiscount />}
              {form.typePromotion !== "Paquete" && <PromotionDiscount />}
              {form.typePromotion !== "Cupón de descuento" && (
                <div className={style.selectsContainer}>
                  <div className={style.selectProducts}>
                    <SelectProducts />
                  </div>
                  <div className={style.selectServices}>
                    <SelectServices />
                  </div>
                  {form.typePromotion === "Paquete" && (
                    <TotalPackageValue total={total} />
                  )}
                </div>
              )}
              {form.typePromotion === "Paquete" && (
                <PackageCost total={total} />
              )}
              <PromotionConditions setCurrentView={setCurrentView} />
              {/* <SelectLounges lounges={lounges} /> */}
              {/* <CareChannels /> */}
            </div>
          </AsideSectionMenu>
        </div>
        <ModalUnsavedChanges
          changesModalVisible={changesModalVisible}
          setChangesModalVisible={setChangesModalVisible}
          handleDiscardChanges={() => {
            navigate("/");
            dispatch(clearForm());
          }}
        />
      </div>
    </PromotionProvider>
  );
};
