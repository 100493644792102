import { useAppDispatch } from "@Hooks/store";
import {
  closeModal,
  updateModalAcceptCancel,
} from "@ReduxService/states/modalAcceptCancelSlice";
import { DeletePromotion } from "@Components/Modals/DeletePromotion/DeletePromotion";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { deleteGiftcardsAdapter } from "@Adapters/giftcards/deleteGiftcards.adapter";
import { useState } from "react";

const useDeleteGiftcards = (id, handleReset) => {
  const dispatch = useAppDispatch();
  const screenWidth = useScreenWidth();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModalDelete = (e) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  const handleCloseModalDelete = (e) => {
    e.stopPropagation();
    setIsVisible(false);
  };

  const modalProps = {
    title: "Eliminar paquete",
    visibility: {
      isVisible: isVisible,
    },
    children: <DeletePromotion />,
    showButtons: {
      showButtonOne: screenWidth > 500,
      showButtonTwo: true,
      showButtonClose: screenWidth <= 500,
    },
    buttonOne: {
      textButtonOne: "Cancelar",
      actionButtonOne: handleCloseModalDelete,
      isDisabled: false,
      isLoading: false,
    },
    buttonTwo: {
      textButtonTwo: "Eliminar",
      actionButtonTwo: (e) =>
        deleteGiftcardsAdapter(
          e,
          id,
          dispatch,
          handleCloseModalDelete,
          setIsLoading,
          handleReset
        ),
      isDisabled: isLoading,
      isLoading: isLoading,
    },
    actionButtonClose: handleCloseModalDelete,
  };

  return { handleOpenModalDelete, modalProps };
};

export default useDeleteGiftcards;
