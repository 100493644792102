import { useNavigate } from "react-router-dom";
// @ts-ignore
import { HeaderNavigation, GiftcardCreationForm } from "@viuti/recursos";
import { useGiftcardForm } from "./hooks/useGiftcardForm";
import style from "./GiftcardForm.module.css";
import { CreateGiftcardNote } from "@Pages/Giftcards/CreateGiftCard/components/CreateGiftcarNote/CreateGiftcardNote";

interface GiftcardFormProps {
  isEdit?: boolean;
  giftcardId?: string;
}

export const GiftcardForm = ({
  isEdit = false,
  giftcardId,
}: GiftcardFormProps) => {
  const navigate = useNavigate();
  const {
    handleSubmitForm,
    handleInputChange,
    giftcardForm,
    giftcardFormError,
    isSubmitting,
    codeError,
    isCodeLoading,
  } = useGiftcardForm(isEdit, giftcardId);

  const buttonProps = {
    textBttn: "Guardar",
    handleClick: () => handleSubmitForm(),
    isLoading: isSubmitting,
    isDisabled: isSubmitting,
    isPrimary: true,
  };

  return (
    <main id="viuti-front-mainContent">
      <HeaderNavigation
        title={isEdit ? "Editar Giftcard" : "Nueva Giftcard"}
        previousAction={() => navigate("/")}
        previousActionMovement={""}
        buttonProps={buttonProps}
      />
      <section className={style.giftcard_main_content}>
        <CreateGiftcardNote />
        <GiftcardCreationForm
          handleInputChange={handleInputChange}
          giftcardForm={giftcardForm}
          giftcardFormError={giftcardFormError}
          codeError={codeError}
          isCodeLoading={isCodeLoading}
        />
      </section>
    </main>
  );
};
