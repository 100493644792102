import { giftcardsBaseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

interface IApiGetGiftcardByIdResponse {
  isSuccess: boolean;
  message: string;
  data: {
    giftcardId: number;
    code: string;
    creationDate: string;
    value: number;
    status: string;
    statusId: number;
    remainder: number;
    fechaVencimiento: string;
  };
}

export const apiGetGiftcardDetails = async (
  giftcardId: string
): Promise<IApiGetGiftcardByIdResponse> => {
  try {
    const PATH = giftcardsBaseUrl + "/GetGiftcardDetails";
    const PARAMS = { giftcardId };
    const AUTHORIZATION = { ...headerAuthorization };

    const CONFIGURATION = {
      params: PARAMS,
      ...AUTHORIZATION,
    };

    const backendResponse = await axios.get(PATH, CONFIGURATION);
    return {
      isSuccess: true,
      message: "Giftcard obtenida correctamente",
      data: backendResponse.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      message: "Error al obtener los detalles de la giftcard",
      data: null,
    };
  }
};
