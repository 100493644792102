import { configureStore } from "@reduxjs/toolkit";

// Components reducers
import notificationReducer from "./states/notificationSlice";
import modalAcceptCancelReducer from "./states/modalAcceptCancelSlice";
import promotionsReducer from "./states/promotionsSlice";
import criticalErrorReducer from "./states/criticalErrorSlice";
import loaderReducer from "./states/loaderSlice";
import servicesSlice from "./states/servicesSlice";
import productsSlice from "./states/productsSlice";
import promotionFormSlice from "./states/promotionsFormSlice";
import promotionsInitialFormSlice from "./states/promotionsInitialFormSlice";
import customerCategorySlice from "./states/customerCategorySlice";
import lastViewSlice from "./states/lastViewSlice";
import giftcardsSlice from "./states/giftcardsSlice";

export const store = configureStore({
  reducer: {
    notificationConfig: notificationReducer,
    modalConfig: modalAcceptCancelReducer,
    promotions: promotionsReducer,
    criticalError: criticalErrorReducer,
    loaderConfig: loaderReducer,
    services: servicesSlice,
    products: productsSlice,
    promotionForm: promotionFormSlice,
    promotionInitialForm: promotionsInitialFormSlice,
    customerCategory: customerCategorySlice,
    lastView: lastViewSlice,

    // 🎯 Giftcards
    giftcards: giftcardsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector = (state: RootState) => RootState;
