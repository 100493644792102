import { giftcardsBaseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export const deleteGiftcards_service = async (id) => {
  const url = `${giftcardsBaseUrl}/DeleteGiftcard`;
  const successMessage = "Giftcard eliminada exitosamente.";
  const errorMessage = "No se pudo eliminar la giftcards. Intente de nuevo.";

  try {
    const response = await axios.delete(url, {
      ...headerAuthorization,
      params: {
        giftcardId: id,
      },
    });

    return {
      isSuccess: true,
      status: 200,
      message: successMessage,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message: errorMessage,
    };
  }
};
